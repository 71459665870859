import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/homepage/business/header';
import Testimonials from '@components/homepage/business/testimonials';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`max-w-xs mt-50 pb-85 lg:flex lg:justify-between lg:mt-0 lg:pt-0 lg:pb-52`}
`;

const Index = () => (
  <Wrapper>
    <Header />
    <Testimonials />
  </Wrapper>
);

export default Index;
