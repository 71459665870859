import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { GatsbyImage } from 'gatsby-plugin-image';
import Text from '@components/text';

const Opinion = tw(Text)`mb-23 text-center lg:mb-40 lg:text-left lg:not-italic`;

const Person = tw.div`flex justify-center items-center lg:justify-start`;

const ImageWrapper = tw.div`flex justify-center items-center w-90 h-90 mr-26 lg:w-110 lg:h-110 lg:mr-30`;

const Image = styled(GatsbyImage)`
  ${tw`w-full`}
  ${({ rounded }) => rounded && tw`rounded-full`}
  ${({ smaller }) => smaller && tw`lg:w-80`}
`;

const Name = tw(Text)`mb-6`;

const Testimonial = ({ testimonial }) => {
  const data = useStaticQuery(graphql`{
    image: file(relativePath: {eq: "for-business/business.png"}) {
      childImageSharp {
        gatsbyImageData(width: 120, placeholder: BLURRED, quality: 80)
      }
    }
    weWork: file(relativePath: {eq: "testimonials/wework.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
    coffeeDesk: file(relativePath: {eq: "testimonials/coffeedesk.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
    konesso: file(relativePath: {eq: "testimonials/konesso.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
    horeca: file(relativePath: {eq: "testimonials/horeca.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
    primulator: file(relativePath: {eq: "testimonials/primulator.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
    unroasted: file(relativePath: {eq: "testimonials/unroasted.png"}) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED, quality: 80)
      }
    }
  }`);

  return (
    <>
      <Opinion fontSizes={24} fontWeight={[400, 300]} lineHeight={1.58} letterSpacing={0} italic>
        <FormattedMessage id={`testimonials.${testimonial.name}.text`} />
      </Opinion>
      <Person>
        <ImageWrapper>
          <Image
            rounded={testimonial.rounded}
            smaller={testimonial.name === 'unroasted'}
            image={data[testimonial.name].childImageSharp.gatsbyImageData}
            alt=""
          />
        </ImageWrapper>
        <div>
          <Name fontSizes={18} fontWeight={700} lineHeight={1.3} letterSpacing={23}>
            <FormattedMessage id={`testimonials.${testimonial.name}.person`} />
          </Name>
          <Text color="blue" fontSizes={18} lineHeight={1.35} letterSpacing={23} underline>
            <FormattedMessage id={`testimonials.${testimonial.name}.cta`} />
          </Text>
        </div>
      </Person>
    </>
  );
};

Testimonial.propTypes = {
  testimonial: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
};

export default Testimonial;
