import React from 'react';
import tw, { styled } from 'twin.macro';

const Element = styled.div`
  top: -63px;
  left: -110px;
  width: 335px;
  height: 335px;
  ${tw`absolute bg-white rounded-full`}
  
  @media (min-width: 1024px) {
    top: -280px;
    left: -280px;
    width: 800px;
    height: 800px;
  }

  @media (min-width: 1280px) {
    top: -235px;
    left: -245px;
  }
`;

const Oval = () => <Element />;

export default Oval;
