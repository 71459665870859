import tw, { styled } from 'twin.macro';

import Text from '@components/text';
import LinkDefault from '@components/link';
import ButtonDefault from '@components/button';

export const Wrapper = styled.div`
  ${tw`relative lg:overflow-hidden`}

  ::before {
    top: -38px;
    transform: rotate(12deg);

    @media (min-width: 492px) { transform: rotate(8deg); }
  }

  ::after {
    z-index: -1;
    bottom: -36px;
    transform: rotate(-4deg);
  }

  ::before, ::after {
    content: '';
    left: -300px;
    right: -300px;
    height: 600px;
    ${tw`absolute bg-pink`}

    @media (min-width: 492px) { height: 600px; }

    @media (min-width: 640px) { height: 480px; }
    
    @media (min-width: 768px) { content: none; }
  }
`;

export const Spacing = styled.div`
  ${tw`mt-98 mx-auto mb-2 max-w-xs md:my-0 lg:mx-0`}

  @media (min-width: 640px) { max-width: 469px; }
  @media (min-width: 768px) { margin-top: 77px; }
  @media (min-width: 1024px) { max-width: 535px; }
`;

export const Container = styled.div`
  ${tw`relative`}
  @media (min-width: 1024px) { width: 1000px; margin: 0 auto; }
  @media (min-width: 1280px) { width: 1105px; }
`;

export const MobileHidden = tw(Text)`hidden md:block`;

export const Hashtag = tw(MobileHidden)`text-white mb-12`;

export const Title = tw(Text)`relative mb-15 lg:mr-60`;

export const Description = tw(MobileHidden)`mb-10`;

export const Content = styled(Text)`
  ${tw`relative px-10 md:px-0`}
  
  b { ${tw`lg:font-600`} }
  
  br { ${tw`md:hidden`} }
`;

export const ContentExtra = tw(Text)`mt-20`;

export const Link = tw(LinkDefault)`relative inline-block md:pb-80 lg:pb-0`;

export const Button = styled(ButtonDefault)`
  width: 269px;
  ${tw`mt-35 py-10 px-20 md:mt-15 lg:mb-16 lg:px-25`}
`;
