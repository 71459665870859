import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`max-w-xs mx-auto mb-7 sm:max-w-none`;

const Subtitle = tw(Text)`max-w-xs mx-auto mb-60 sm:max-w-none lg:mb-36`;

const Header = () => (
  <>
    <Title fontFamily="bebasNeue" fontSizes={36} lineHeight={[1.06, 1.58]} letterSpacing={45}>
      <FormattedMessage id="homepage.3 ways.title" />
    </Title>
    <Subtitle
      fontFamily="bebasNeue"
      fontSizes={[42, 56]}
      fontWeight={700}
      lineHeight={[1.05, 1.01]}
      letterSpacing={[52, 70]}
    >
      <FormattedMessage id="homepage.3 ways.subtitle" />
    </Subtitle>
  </>
);

export default Header;
