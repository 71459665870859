import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import ImagesContainer from '@components/homepage/slider/web/slider/images-container';

const Wrapper = styled.div`
  transform: translateX(${({ slide }) => slide && `calc(${slide * -100}% - ${slide * 320}px)`});
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  ${tw`hidden lg:absolute lg:inset-0 lg:flex lg:flex-nowrap transition-transform duration-400`}
`;

const Image = tw(GatsbyImage)`absolute!`;

const Roast = styled(Image)`
  right: -270px;
  bottom: -37px;
  width: 605px;
  
  @media (min-width: 1280px) {
    right: -210px;
  }
`;

const Brew = styled(Image)`
  right: -270px;
  bottom: -33px;
  width: 540px;
  
  @media (min-width: 1280px) {
    right: -220px;
  }
`;

const CafeSecond = styled(Image)`
  left: -235px;
  bottom: -154px;
  width: 200px;
  ${tw`hidden xl:block`}
`;

const CityMap = styled(Image)`
  left: -180px;
  bottom: -200px;
  width: 1440px;
  
  @media (min-width: 1441px) {
    left: -420px;
    bottom: -145px;
    width: 1920px;
    height: 520px;
  }

  @media (min-width: 1921px) {
    left: -760px;
    bottom: -155px;
    width: 2590px;
  }
`;

const Serve = styled(Image)`
  left: -218px;
  bottom: -159px;
  width: 500px;
  transform: scaleY(-1);
`;

const Cafe = styled(Image)`
  right: -120px;
  bottom: 96px;
  width: 235px;

  @media (min-width: 1280px) {
    right: -240px;
    bottom: 71px;
  }
`;

const Images = ({ activeSlide }) => {
  const data = useStaticQuery(graphql`
    {
      roast: file(relativePath: {eq: "homepage/we-roast.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
      brew: file(relativePath: {eq: "homepage/we-brew.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
      cafeSecoond: file(relativePath: {eq: "cafe-second.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
      cityMap: file(relativePath: {eq: "homepage/map.png"}) {
        childImageSharp {
          gatsbyImageData(width: 2600, placeholder: BLURRED, quality: 80)
        }
      }
      serve: file(relativePath: {eq: "homepage/we-serve.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
      cafe: file(relativePath: {eq: "cafe.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
    }
  `);

  return (
    <Wrapper slide={activeSlide}>
      <ImagesContainer>
        <Roast image={data.roast.childImageSharp.gatsbyImageData} alt="" />
      </ImagesContainer>
      <ImagesContainer>
        <Brew image={data.brew.childImageSharp.gatsbyImageData} alt="" />
        <CafeSecond fluid={data.cafeSecoond.childImageSharp.gatsbyImageData} />
      </ImagesContainer>
      <ImagesContainer>
        <CityMap image={data.cityMap.childImageSharp.gatsbyImageData} alt="" />
        <Serve image={data.serve.childImageSharp.gatsbyImageData} alt="" />
        <Cafe image={data.cafe.childImageSharp.gatsbyImageData} alt="" />
      </ImagesContainer>
    </Wrapper>
  );
};

Images.propTypes = {
  activeSlide: PropTypes.number.isRequired,
};

export default Images;
