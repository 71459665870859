import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage, FormattedMessage } from 'gatsby-plugin-intl';

import Link from '@components/link';
import Text from '@components/text';
import CoffeeDetails from '@components/homepage/just-coffee/coffee-details';

const Wrapper = styled.div`
  &.etnocafe { ${tw`lg:ml-15`} }
`;

const ImageWrapper = styled.div`
  @media (min-width: 1024px) {
    min-height: 174px;
    ${tw`lg:flex lg:items-end`}
  }
`;

const Image = tw(GatsbyImage)`mx-auto mb-35 w-150 lg:m-0`;

const Description = styled(Text)`
  ${tw`mt-15 mx-auto mb-50 lg:hidden`}
  
  ${Wrapper}.brew & { ${tw`mb-25`} }
  
  br { ${tw`hidden`} }
  
  @media (min-width: 640px) {
    max-width: 500px;
  }
`;

const Coffee = ({ type }) => {
  const data = useStaticQuery(graphql`{
    etnoCafe: file(relativePath: {eq: "homepage/etnocafe.png"}) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: BLURRED, quality: 80)
      }
    }
    wroasters: file(relativePath: {eq: "homepage/wroasters.png"}) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: BLURRED, quality: 80)
      }
    }
    coldBrew: file(relativePath: {eq: "homepage/cbc.png"}) {
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: BLURRED, quality: 80)
      }
    }
  }`);

  return (
    <Wrapper className={type}>
      <Link to="/coffee" hash={type}>
        <ImageWrapper>
          <Image image={data[type].childImageSharp.gatsbyImageData} alt="" />
        </ImageWrapper>
      </Link>
      <Link to="/coffee" hash={type}>
        <Text fontFamily="bebasNeue" fontSizes={28} fontWeight={700} lineHeight={[1, 2.03]} letterSpacing={35}>
          <FormattedMessage id={`homepage.just coffee.types.${type}.title`} />
        </Text>
      </Link>
      <Description fontSizes={16} fontWeight={[400, 300]} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id={`homepage.just coffee.types.${type}.description`} />
      </Description>
      <CoffeeDetails type={type} />
    </Wrapper>
  );
};

Coffee.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Coffee;
