import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Description = tw(Text)`mt-2`;

const Image = styled(GatsbyImage)`
  left: ${({ type }) => type === 'serve' && -12}px;
  ${({ type }) => (type === 'serve' ? tw`w-175` : tw`w-155`)}
  ${tw`relative mx-auto mb-25`}
  
  @media (min-width: 1280px) {
    width: ${({ type }) => (type === 'serve' ? 180 : 155)}px;
  }
`;

const NavItem = ({ type }) => {
  const data = useStaticQuery(graphql`
    {
      roast: file(relativePath: {eq: "homepage/roast.png"}) {
        childImageSharp {
          gatsbyImageData(width: 240, placeholder: BLURRED, quality: 80)
        }
      }
      brew: file(relativePath: {eq: "homepage/brew.png"}) {
        childImageSharp {
          gatsbyImageData(width: 240, placeholder: BLURRED, quality: 80)
        }
      }
      serve: file(relativePath: {eq: "homepage/serve.png"}) {
        childImageSharp {
          gatsbyImageData(width: 240, placeholder: BLURRED, quality: 80)
        }
      }
    }
  `);

  return (
    <div>
      <Image type={type} image={data[type].childImageSharp.gatsbyImageData} alt="" />
      <Text fontFamily="bebasNeue" fontSizes={36} lineHeight={1.2} letterSpacing={45}>
        <FormattedMessage id={`homepage.3 ways.slider.${type}.hash`} />
      </Text>
      <Description
        fontSizes={[16, 14]}
        fontWeight={300}
        lineHeight={[1.2, 1.4]}
        letterSpacing={[20, 18]}
      >
        <FormattedMessage id={`homepage.3 ways.slider.${type}.desc`} />
      </Description>
    </div>
  );
};

NavItem.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NavItem;
