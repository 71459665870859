import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';
import { navigationCoffee } from '@utils/navigation';

const Link = tw(LinkDefault)`inline-block mt-10 lg:mt-30`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-20`;

const CTA = () => (
  <>
    <Link to={navigationCoffee.path} isExternal={navigationCoffee.externalLink}>
      <Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id={navigationCoffee.translation} />
        </Text>
      </Button>
    </Link>
  </>
);

export default CTA;
