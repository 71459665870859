import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/homepage/we-care/header';
import List from '@components/homepage/we-care/list';
import CTA from '@components/homepage/we-care/cta';
import Visual from '@components/homepage/we-care/visual';

const Container = styled.div`
  ${customContainer}
  ${tw`
    relative px-11 pb-77
    md:pt-40 md:pb-100
    lg:pt-150 lg:px-0 lg:pb-160
    xl:pt-126 xl:pb-182
  `}
`;

const WeCare = ({ lang }) => (
  <Container>
    <Header />
    <List />
    <CTA />
    <Visual lang={lang} />
  </Container>
);

WeCare.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default WeCare;
