import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import NavItem from '@components/homepage/slider/nav-item';

const Wrapper = tw.div`relative flex justify-center items-end z-1`;

const NavWrapper = tw.div`
  mx-40 cursor-pointer transition-opacity duration-300 transition-opacity duration-300
  hover:opacity-80
  lg:mx-60
`;

const Nav = ({ items, activeSlide, setActiveSlide }) => (
  <Wrapper>
    {items.map((item, index) => (
      <NavWrapper key={item} onClick={() => activeSlide !== index && setActiveSlide(index)}>
        <NavItem type={item} />
      </NavWrapper>
    ))}
  </Wrapper>
);

Nav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default Nav;
