import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigationAbout } from '@utils/navigation';

import Text from '@components/text';
import LinkDefault from '@components/link';
import ArrowDefault from '@images/arrow.svg';

const Link = tw(LinkDefault)`flex items-center mt-80 lg:mt-62`;

const Arrow = styled(ArrowDefault)`
  ${tw`w-23 ml-27 transition-all duration-300`}
  
  ${Link}:hover & {
    ${tw`ml-32`}
  }
`;

const CTA = () => (
  <Link to={navigationAbout.path}>
    <Text
      fontSizes={16}
      fontWeight={600}
      lineHeight={1.4}
      letterSpacing={20}
      underline
      uppercase
    >
      <FormattedMessage id={navigationAbout.translation} />
    </Text>
    <Arrow />
  </Link>
);

export default CTA;
