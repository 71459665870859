import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`hidden lg:block lg:relative lg:mt-13`;

const Description = styled(Text)`
  max-width: 215px;
  
  br { ${tw`hidden`} }

  ${Wrapper}.etnocafe & {
    max-width: 230px;

    br { ${tw`block`} }
  }
`;

const Shadow = styled.div`
  bottom: 30px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255,255,255, 0.8) 5px, rgba(255,255,255, 1) 35px, #FFF 100%);
  ${tw`hidden lg:block lg:absolute lg:w-full lg:h-26 transition-all duration-400`}
  
  ${Wrapper}.open & { ${tw`h-0 opacity-0`} }
`;

const LoadMore = styled(Text)`
  ${tw`
    hidden relative mt-12 mb-20 cursor-pointer overflow-hidden transition-all duration-400
    lg:block
  `}

  ${Wrapper}.open & { ${tw`my-0 opacity-0 cursor-default`} }
`;

const CoffeeDetails = ({ type }) => {
  const [isOpen, handleOpen] = useState(false);

  return (
    <Wrapper className={`${type} ${isOpen && 'open'}`}>
      <AnimateHeight duration={400} height={isOpen ? 'auto' : 116}>
        <Description fontSizes={16} fontWeight={[400, 300]} lineHeight={1.5} letterSpacing={20}>
          <FormattedHTMLMessage id={`homepage.just coffee.types.${type}.description`} />
        </Description>
      </AnimateHeight>
      <Shadow hidden={isOpen} />
      <LoadMore
        onClick={() => handleOpen(true)}
        fontSizes={14}
        fontWeight={600}
        lineHeight={1.35}
        letterSpacing={18}
        underline
        uppercase
      >
        <FormattedMessage id="btn.see more" />
      </LoadMore>
    </Wrapper>
  );
};

CoffeeDetails.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CoffeeDetails;
