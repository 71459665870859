import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const TextInline = tw(Text)`relative inline-block mt-4 mr-12 lg:mt-8 lg:mr-16`;

const Nature = tw(Text)`mb-10`;

const Subtitle = styled(Text)`
  max-width: 260px;
  ${tw`whitespace-normal lg:whitespace-pre-line`}
  
  @media (min-width: 1024px) {
    max-width: 390px;
  }
`;

const Content = () => (
  <>
    <Text color="green" fontSizes={[10, 16]} lineHeight={1.4} letterSpacing={[13, 20]} uppercase>
      <FormattedMessage id="homepage.header.hashtags" />
    </Text>
    <TextInline fontFamily="bebasNeue" fontWeight={[400, 300]} fontSizes={[36, 64]} letterSpacing={[0, 8]}>
      <FormattedMessage id="homepage.header.title.just" />
    </TextInline>
    <TextInline fontFamily="bebasNeue" fontSizes={[43, 72]} fontWeight={700} letterSpacing={[0, 9]}>
      <FormattedMessage id="homepage.header.title.cafe" />
    </TextInline>
    <Nature fontFamily="bebasNeue" fontSizes={[29, 54]} fontWeight={300} letterSpacing={[0, 67]} lineHeight={1.2} uppercase>
      <FormattedMessage id="homepage.header.title.nature" />
    </Nature>
    <Subtitle
      fontSizes={[14, 18]}
      fontWeight={[400, 300]}
      lineHeight={1.4}
      letterSpacing={[18, 23]}
    >
      <FormattedMessage id="homepage.header.subtitle" />
    </Subtitle>
  </>
);

export default Content;
