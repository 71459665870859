import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const PaginationBarWrapper = styled.div`
  width: 520px;
  ${tw`relative mt-20 mx-auto`}

  @media (min-width: 1024px) { width: 570px; }
`;

const PaginationBar = styled.div`
  background-color: #E1C7BE;
  ${tw`w-full h-1`}
`;

const PaginationActiveItem = styled(GatsbyImage)`
  top: 50%;
  left: -2px;
  width: 11px;
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  ${tw`absolute! transition-all duration-400 transform -translate-y-1/2`}
  
  ${({ slide }) => slide === 1 && css`left: calc(50% - 5px);`}
  ${({ slide }) => slide === 2 && css`left: calc(100% - 11px);`}
`;

const PaginationBarPoint = styled.div`
  top: -2px;
  background-image: linear-gradient(to bottom, #dfc3b9 22%, #fcfcfc 99%);
  ${tw`absolute left-0 w-6 h-6 rounded-full cursor-pointer`}
  
  :nth-of-type(3) { ${tw`left-1/2 transform -translate-x-1/2`} }
  
  :nth-of-type(4) {
    left: initial;
    right: 0;
  }
`;

const ProgressBar = ({ items, activeSlide, setActiveSlide }) => {
  const data = useStaticQuery(graphql`
    {
      active: file(relativePath: {eq: "cafe-second.png"}) {
        childImageSharp {
          gatsbyImageData(width: 20, placeholder: BLURRED, quality: 80)
        }
      }
    }
  `);

  return (
    <PaginationBarWrapper>
      <PaginationBar />
      {items.map((item, index) => (
        <PaginationBarPoint
          key={item}
          onClick={() => activeSlide !== index && setActiveSlide(index)}
        />
      ))}
      <PaginationActiveItem
        slide={activeSlide}
        image={data.active.childImageSharp.gatsbyImageData}
        alt=""
      />
    </PaginationBarWrapper>
  );
};

ProgressBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default ProgressBar;
