import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`relative`}
`;

const ImageLeft = styled(GatsbyImage)`
  top: 2px;
  right: -268px;
  ${tw`hidden w-210 xl:block absolute!`}
`;

const ImageRight = styled(GatsbyImage)`
  top: -269px;
  left: -250px;
  ${tw`hidden w-150 xl:block absolute!`}
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    {
      coffeeMark: file(relativePath: {eq: "coffee-mark.png"}) {
        childImageSharp {
          gatsbyImageData(width: 250, placeholder: BLURRED, quality: 80)
        }
      }
    }
  `);

  return (
    <Wrapper>
      <ImageLeft image={data.coffeeMark.childImageSharp.gatsbyImageData} alt="" />
      <ImageRight image={data.coffeeMark.childImageSharp.gatsbyImageData} alt="" />
    </Wrapper>
  );
};

export default Images;
