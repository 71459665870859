import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { customContainer } from '@utils/twin-macro';
import { navigationAbout } from '@utils/navigation';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import Link from '@components/link';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`relative px-10 pb-36 text-center lg:flex lg:justify-between lg:px-0 lg:text-left`}
`;

const ContentWrapper = tw.div`
  max-w-xs mx-auto 
  sm:max-w-480 
  lg:w-440 lg:m-0 lg:pt-130 lg:pb-100
  xl:pt-207 xl:pb-150
`;

const Title = tw(Text)`mt-6 mb-470 sm:mb-580 lg:mt-4 lg:mb-15`;

const Button = tw(ButtonDefault)`mt-35 mx-0 mb-0 lg:mt-30`;

const Collage = styled(GatsbyImage)`
  top: 180px;
  right: -60px;
  width: 346px;
  ${tw`absolute! lg:top-0`}
  
  @media (min-width: 640px) {
    right: 20px;
    width: 460px;
  }
  
  @media (min-width: 768px) {
    right: 90px;
  }
  
  @media (min-width: 1024px) {
    left: 520px;
    right: initial;
    width: 570px;
  }

  @media (min-width: 1280px) {
    left: 639px;
    width: 746px;
  }
`;

const Mark = styled(GatsbyImage)`
  ${tw`absolute! hidden lg:block`}
  
  @media (min-width: 1024px) {
    top: 90px;
    left: 570px;
    width: 140px;
  }

  @media (min-width: 1280px) {
    top: 94px;
    left: 700px;
    width: 203px;
  }
`;

const ForYou = () => {
  const data = useStaticQuery(graphql`
    {
      collage: file(relativePath: {eq: "homepage/for-you.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
      mark: file(relativePath: {eq: "coffee-mark.png"}) {
        childImageSharp {
          gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
        }
      }
    }
  `);

  return (
    <Wrapper>
      <ContentWrapper>
        <Text color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
          <FormattedMessage id="homepage.for you.hashtag" />
        </Text>
        <Title fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={700} lineHeight={1.08} letterSpacing={[52, 72]} uppercase>
          <FormattedMessage id="homepage.for you.title" />
        </Title>
        <Text fontSizes={16} fontWeight={[400, 300]} lineHeight={1.5} letterSpacing={20}>
          <FormattedMessage id="homepage.for you.description" />
        </Text>
        <Link to={navigationAbout.path} isExternal={navigationAbout.externalLink}>
          <Button>
            <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
              <FormattedMessage id={navigationAbout.translation} />
            </Text>
          </Button>
        </Link>
      </ContentWrapper>
      <Mark image={data.mark.childImageSharp.gatsbyImageData} alt="" />
      <Collage image={data.collage.childImageSharp.gatsbyImageData} alt="" />
    </Wrapper>
  );
};

export default ForYou;
