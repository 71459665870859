import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Oval from '@components/homepage/header/oval';
import Content from '@components/homepage/header/content';
import Collage from '@components/homepage/header/collage';
import CTA from '@components/homepage/header/cta';

const calcBgWidth = (val) => css`width: calc(50% + ${val}px);`;

const Wrapper = tw.div`relative`;

const Background = styled.div`
  border-radius: 0 56px 0 0;
  ${tw`absolute w-full h-full bg-backgroundGreen`}

  @media (min-width: 768px) { ${calcBgWidth(384)} }

  @media (min-width: 1024px) { ${calcBgWidth(585)} }

  @media (min-width: 1280px) { ${calcBgWidth(675)} }
`;

const Container = styled.div`
  ${tw`relative md:flex md:justify-between`}
  ${customContainer}
`;

const ContentWrapper = tw.div`relative pt-90 pl-10 pb-77 lg:pt-100 lg:px-0 lg:pb-140 xl:pt-140 xl:pb-265`;

const Header = () => (
  <Wrapper>
    <Background />
    <Container>
      <Oval />
      <ContentWrapper>
        <Content />
        <CTA />
      </ContentWrapper>
      <Collage />
    </Container>
  </Wrapper>
);

export default Header;
