import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import { navigationWeRoast } from '@utils/navigation';
import * as Styles from '@components/homepage/slider/styles';

const Roast = () => (
  <Styles.Wrapper>
    <Styles.Container>
      <Styles.Spacing>
        <Styles.Hashtag fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
          <FormattedMessage id="homepage.3 ways.slider.roast.hash" />
        </Styles.Hashtag>
        <Styles.Title
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={700}
          lineHeight={[1.05, 1.02]}
          letterSpacing={[52, 70]}
        >
          <FormattedMessage id="homepage.3 ways.slider.roast.title" />
        </Styles.Title>
        <Styles.Description fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedHTMLMessage id="homepage.3 ways.slider.roast.desc" />
        </Styles.Description>
        <Styles.Content
          fontSizes={16}
          fontWeight={[400, 300]}
          lineHeight={[1.5, 1.4]}
          letterSpacing={20}
        >
          <FormattedHTMLMessage id="homepage.3 ways.slider.roast.content" />
        </Styles.Content>
        <Styles.ContentExtra
          fontSizes={16}
          fontWeight={[400, 300]}
          lineHeight={1.4}
          letterSpacing={20}
        >
          <FormattedHTMLMessage id="homepage.3 ways.slider.roast.extra content" />
        </Styles.ContentExtra>
        <Styles.Link
          to={navigationWeRoast.path}
          hash={navigationWeRoast.hash}
          isExternal={navigationWeRoast.externalLink}
        >
          <Styles.Button>
            <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
              <FormattedMessage id={navigationWeRoast.translation} />
            </Text>
          </Styles.Button>
        </Styles.Link>
      </Styles.Spacing>
    </Styles.Container>
  </Styles.Wrapper>
);

export default Roast;
