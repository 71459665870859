import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import CTA from '@components/homepage/business/cta';

const Wrapper = styled.div`
  ${tw`px-10 text-center sm:mx-auto lg:max-w-none lg:m-0 lg:px-0 lg:text-left`}

  @media (min-width: 640px) { max-width: 550px; }
  
  @media (min-width: 1024px) { max-width: 445px; }
`;

const Title = tw(Text)`mt-6 pb-24 lg:mt-9 lg:pb-0`;

const Subtitle = tw(Text)`mb-24 lg:mb-36`;

const Details = tw(Text)`inline-block`;

const Description = styled(Text)`
  ${tw`mt-10 lg:mt-18`}
  
  br { ${tw`lg:hidden`} }
`;

const Header = () => (
  <Wrapper>
    <Text color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="homepage.business.hashtag" />
    </Text>
    <Title fontFamily="bebasNeue" fontSizes={[42, 56]} fontWeight={700} lineHeight={1.1} letterSpacing={52} uppercase>
      <FormattedMessage id="homepage.business.title" />
    </Title>
    <Subtitle fontSizes={16} fontWeight={700} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="homepage.business.subtitle" />
    </Subtitle>
    <Link to="/for-business">
      <Details fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="homepage.business.details" />
      </Details>
    </Link>
    <Description fontSizes={16} fontWeight={[400, 300]} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="homepage.business.desc" />
    </Description>
    <CTA />
  </Wrapper>
);

export default Header;
