import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

const Slide = tw.div`min-w-full h-full mr-320`;

const Container = styled.div`
  ${customContainer}
  ${tw`relative h-full`}
`;

const ImagesContainer = ({ children }) => (
  <Slide>
    <Container>
      { children }
    </Container>
  </Slide>
);

ImagesContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImagesContainer;
