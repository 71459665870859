import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import tw, { styled } from 'twin.macro';

import NavItem from '@components/homepage/slider/nav-item';
import Roast from '@components/homepage/slider/roast';
import Brew from '@components/homepage/slider/brew';
import Serve from '@components/homepage/slider/serve';

const Wrapper = tw.div`md:hidden`;

const ClickableWrapper = tw.div`mt-30`;

const HeightComponent = styled(AnimateHeight)`
  top: -30px;
  will-change: height;
  ${tw`relative`}
`;

const Mobile = () => {
  const [heightBrew, setHeightBrew] = useState('auto');
  const [heightRoast, setHeightRoast] = useState(0);
  const [heightServe, setHeightServe] = useState(0);

  return (
    <Wrapper>
      <ClickableWrapper onClick={() => setHeightBrew(heightBrew === 0 ? 'auto' : 0)}>
        <NavItem type="roast" />
      </ClickableWrapper>
      <HeightComponent duration={500} easing="linear" height={heightBrew}>
        <Roast />
      </HeightComponent>
      <ClickableWrapper onClick={() => setHeightRoast(heightRoast === 0 ? 'auto' : 0)}>
        <NavItem type="brew" />
      </ClickableWrapper>
      <HeightComponent duration={450} easing="linear" height={heightRoast}>
        <Brew />
      </HeightComponent>
      <ClickableWrapper onClick={() => setHeightServe(heightServe === 0 ? 'auto' : 0)}>
        <NavItem type="serve" />
      </ClickableWrapper>
      <HeightComponent duration={700} easing="linear" height={heightServe}>
        <Serve />
      </HeightComponent>
    </Wrapper>
  );
};

export default Mobile;
