import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`mt-11 mb-330 md:mb-10 lg:mt-15 lg:mb-10`;

const Subtitle = styled(Text)`
  max-width: 400px;
  ${tw`mx-auto mb-25 text-center md:mx-0 md:text-left lg:mb-40`}
`;

const Header = () => (
  <>
    <Text color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="homepage.we care.hashtag" />
    </Text>
    <Title fontFamily="bebasNeue" fontSizes={[40, 58]} fontWeight={700} lineHeight={[1.1, 0.99]} letterSpacing={[0, 72]} uppercase>
      <FormattedHTMLMessage id="homepage.we care.title" />
    </Title>
    <Subtitle fontSizes={16} fontWeight={[400, 300]} lineHeight={1.4} letterSpacing={20}>
      <FormattedMessage id="homepage.we care.subtitle" />
    </Subtitle>
  </>
);

export default Header;
