import React from 'react';
import tw from 'twin.macro';

import Header from '@components/homepage/slider/header';
import Mobile from '@components/homepage/slider/mobile';
import Web from '@components/homepage/slider/web';

const Wrapper = tw.div`text-center`;

const Index = () => (
  <Wrapper>
    <Header />
    <Mobile />
    <Web />
  </Wrapper>
);

export default Index;
