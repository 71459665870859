import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { testimonials } from '@utils/variables';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import SwipeableViews from 'react-swipeable-views';
import Testimonial from '@components/homepage/business/testimonial';

const Wrapper = styled.div`
  ${tw`px-10 sm:mx-auto lg:max-w-none lg:m-0 lg:pt-36 lg:px-0`}

  @media (min-width: 640px) { max-width: 550px; }

  @media (min-width: 1024px) { width: 470px; }

  @media (min-width: 1280px) { width: 530px; }
`;

const Cooperation = tw(Text)`mb-18 text-center lg:mb-28 lg:text-left`;

const SwipeableViewsWrapper = tw.div`relative transition-all duration-400`;

const Slide = tw.div`flex flex-col justify-center h-full lg:justify-end`;

const Pagination = tw.div`flex justify-center items-center mt-15`;

const DotWrapper = tw.div`flex justify-center items-center w-20 h-12`;

const Dot = styled.div`
  ${tw`w-10 h-10 bg-brownDot opacity-50 rounded-full transition-all duration-300 cursor-pointer`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Testimonials = () => {
  const [activeTestimonial, handleTestimonial] = useState(0);

  return (
    <Wrapper>
      <Cooperation fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="homepage.business.cooperation" />
      </Cooperation>
      <SwipeableViewsWrapper firstSlide={activeTestimonial === 0}>
        <SwipeableViews
          index={activeTestimonial}
          onChangeIndex={handleTestimonial}
        >
          {testimonials.map((testimonial, index) => (
            <Slide
              key={testimonial.name}
              onClick={() => handleTestimonial(index)}
            >
              <Testimonial testimonial={testimonial} />
            </Slide>
          ))}
        </SwipeableViews>
      </SwipeableViewsWrapper>
      <Pagination>
        {testimonials.map((testimonial, index) => (
          <DotWrapper
            key={`${testimonial.name}id`}
            onClick={() => index !== activeTestimonial && handleTestimonial(index)}
          >
            <Dot active={index === activeTestimonial} />
          </DotWrapper>
        ))}
      </Pagination>
    </Wrapper>
  );
};

export default Testimonials;
