import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';

const Image = styled(GatsbyImage)`
  ${tw`
    absolute! hidden
    md:block md:inset-y-0 md:right-0 md:w-360 md:-mt-6
    lg:w-480
    xl:w-620 xl:-mt-10
  `}

  @media (min-width: 1024px) {
    bottom: -1px;
    right: -85px;
  }
  
  @media (min-width: 1280px) {
    right: -122px;
  }
`;

const Collage = () => {
  const data = useStaticQuery(graphql`{
    collage: file(relativePath: {eq: "homepage/main.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700, placeholder: BLURRED, quality: 80)
      }
    }
  }`);

  return <Image image={data.collage.childImageSharp.gatsbyImageData} alt="" />;
};

export default Collage;
