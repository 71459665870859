import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';

const Wrapper = styled.div`
  top: ${({ lang }) => (lang === 'pl' ? 146 : 188)}px;
  width: 276px;
  height: 276px;
  transform: translateX(-52%);
  ${tw`
    absolute left-1/2 bg-backgroundBlue rounded-full
    md:top-1/2 md:transform md:-translate-y-1/2
    lg:top-0 lg:transform-none
  `}
  
  @media (min-width: 768px) {
    left: initial;
    right: -100px;
    width: 420px;
    height: 420px;
  }

  @media (min-width: 1024px) {
    left: 496px;
    width: 774px;
    height: 778px;
  }
`;

const YellowBourbon = styled(GatsbyImage)`
  top: 60px;
  left: 147px;
  width: 85px;
  ${tw`absolute!`}

  @media (min-width: 768px) {
    top: 90px;
    left: 208px;
    width: 122px;
  }
  
  @media (min-width: 1024px) {
    top: 148px;
    left: 350px;
    width: 214px;
  }

  @media (min-width: 1280px) {
    top: 164px;
    left: 408px;
    width: 240px;
  }
`;

const ColombiaMedellin = styled(GatsbyImage)`
  top: 9px;
  left: 44px;
  width: 111px;
  
  @media (min-width: 768px) {
    top: 20px;
    left: 60px;
    width: 160px;
  }

  @media (min-width: 1024px) {
    top: 25px;
    left: 90px;
    width: 280px;
  }

  @media (min-width: 1280px) {
    top: 27px;
    left: 122px;
    width: 311px;
  }
`;

const Visual = ({ lang }) => {
  const data = useStaticQuery(graphql`{
    yellowBourbon: file(relativePath: {eq: "homepage/yellow-bourbon.png"}) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED, quality: 80)
      }
    }
    colombiaMedellin: file(relativePath: {eq: "homepage/colombia-medellin.png"}) {
      childImageSharp {
        gatsbyImageData(width: 350, placeholder: BLURRED, quality: 80)
      }
    }
  }`);

  return (
    <Wrapper lang={lang}>
      <YellowBourbon image={data.yellowBourbon.childImageSharp.gatsbyImageData} alt="" />
      <ColombiaMedellin image={data.colombiaMedellin.childImageSharp.gatsbyImageData} alt="" />
    </Wrapper>
  );
};

Visual.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Visual;
