import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import Images from '@components/homepage/slider/web/images';
import Nav from '@components/homepage/slider/web/nav';
import ProgressBar from '@components/homepage/slider/web/progress-bar';
import Slider from '@components/homepage/slider/web/slider';
import Pagination from '@components/homepage/slider/web/pagination';

const items = ['roast', 'brew', 'serve'];

const Wrapper = styled.div`
  ${tw`hidden md:block md:mb-120`}
  
  ${({ slide }) => (slide === 2 ? tw`lg:mb-220` : tw`lg:mb-110`)}
`;

const Web = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Wrapper slide={activeSlide}>
      <Images />
      <Nav activeSlide={activeSlide} setActiveSlide={setActiveSlide} items={items} />
      <ProgressBar activeSlide={activeSlide} setActiveSlide={setActiveSlide} items={items} />
      <Slider activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
      <Pagination activeSlide={activeSlide} setActiveSlide={setActiveSlide} items={items} />
    </Wrapper>
  );
};

export default Web;
