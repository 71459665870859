import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/homepage/just-coffee/header';
import Coffee from '@components/homepage/just-coffee/coffee';
import CTA from '@components/homepage/just-coffee/cta';

const coffeeTypes = ['etnoCafe', 'wroasters', 'coldBrew'];

const Container = styled.div`
  ${customContainer}
  ${tw`relative max-w-xs text-center px-25 pb-62 lg:pb-80`}
`;

const CoffeeTypes = tw.div`lg:flex lg:justify-between lg:text-left`;

const JustCoffee = () => (
  <Container>
    <Header />
    <CoffeeTypes>
      {coffeeTypes.map((type) => (
        <Coffee key={type} type={type} />
      ))}
    </CoffeeTypes>
    <CTA />
  </Container>
);

export default JustCoffee;
