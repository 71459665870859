import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { array } from '@utils/helpers';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const ListItem = styled.div`
  ${tw`flex -mr-5 mb-15 lg:mb-25`}
  
  @media (min-width: 377px) {
    max-width: 360px;
    
    br { ${tw`hidden`} }
  }

  @media (min-width: 1024px) {
    min-width: 470px;
    
    b { ${tw`font-600`} }
  }
`;

const Icon = styled(GatsbyImage)`
  top: 2px;
  min-width: 20px;
  ${tw`relative h-20 mr-15 bg-black rounded-full lg:top-0`}
`;

const List = () => {
  const data = useStaticQuery(graphql`{
  listStyle: file(relativePath: {eq: "cafe-second.png"}) {
    childImageSharp {
      gatsbyImageData(width: 40, layout: CONSTRAINED)
    }
  }
}
`);

  return (
    <>
      {array(4).map((number) => (
        <ListItem key={number}>
          <Icon fluid={data.listStyle.childImageSharp.gatsbyImageData} />
          <Text fontSizes={16} fontWeight={[400, 300]} lineHeight={1.4} letterSpacing={20}>
            <FormattedHTMLMessage id={`homepage.we care.list.${number}`} />
          </Text>
        </ListItem>
      ))}
    </>
  );
};

export default List;
