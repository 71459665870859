import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';
import { navigationSeeCoffee } from '@utils/navigation';

const Link = tw(LinkDefault)`inline-block lg:mt-5`;

const Button = tw(ButtonDefault)`px-30`;

const CTA = () => (
  <>
    <Link to={navigationSeeCoffee.path} isExternal={navigationSeeCoffee.externalLink}>
      <Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id={navigationSeeCoffee.translation} />
        </Text>
      </Button>
    </Link>
  </>
);

export default CTA;
