import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { customContainer } from '@utils/twin-macro';

import Text from '@components/text';
import MarkDefault from '@images/mark.svg';
import ShadowDefault from '@images/shadow.svg';

const Container = styled.div`
  ${customContainer}
  ${tw`relative py-65 text-center md:pt-110 md:pb-102`}
`;

const AtEtno = styled(Text)`
  top: -8px;
  ${tw`md:inline-block lg:relative`}
`;

const Mindfulness = tw(Text)`
  relative max-w-xs mt-12 mx-auto mb-8
  md:inline-block md:max-w-none md:mt-0 md:ml-10 md:mb-10
  lg:ml-15
`;

const TextWrapper = styled.span`
  z-index: 1;
  ${tw`relative inline-block`}
  
  ::before, ::after {
    content: '';
    ${tw`absolute left-1/2 h-3 bg-black transform -translate-x-1/2`}
  }
  
  ::before {
    top: 48px;
    width: ${({ lang }) => (lang === 'pl' ? 262 : 220)}px;
  }

  ::after {
    top: 106px;
    width: ${({ lang }) => (lang === 'pl' ? 182 : 234)}px;
  }
  
  @media (min-width: 768px) {
    ::before {
      width: 458px;
    }

    ::after {
      content: none;
    }
  }

  @media (min-width: 1024px) {
    ::before {
      top: 68px;
      width: 594px;
      height: 4px;
    }
  }
`;

const Mark = styled(MarkDefault)`
  top: 4px;
  left: -4px;
  transform: rotate(0deg);
  width: 320px;
  height: 120px;
  ${tw`absolute md:w-500 lg:w-610`}

  .color {
    fill: rgba(242, 193, 182, 0.72);
  }
  
  @media (min-width: 768px) {
    top: -27px;
    left: -50px;
    height: 105px;
    transform: rotate(0deg);
  }
  
  @media (min-width: 1024px) {
    left: -38px;
    top: -28px;
    width: 620px;
    height: 140px;
  }
`;

const Cafe = styled(GatsbyImage)`
  top: -70px;
  right: -22px;
  ${tw`absolute! w-130 md:w-160 lg:w-350`}
  
  @media (min-width: 768px) {
    top: 160px;
    right: initial;
    left: -80px;
  }

  @media (min-width: 1024px) {
    top: 170px;
    left: -200px;
  }

  @media (min-width: 1280px) {
    top: 140px;
    left: -233px;
  }
`;

const Shadow = styled(ShadowDefault)`
  ${tw`absolute hidden md:block md:w-160 lg:w-350 xl:w-340`}

  @media (min-width: 768px) {
    top: 62px;
    left: -88px;
  }

  @media (min-width: 1024px) {
    top: 150px;
    left: -215px;
  }

  @media (min-width: 1280px) {
    top: 120px;
    left: -258px;
  }
`;

const Mindfulnesss = ({ lang }) => {
  const data = useStaticQuery(graphql`{
    cafe: file(relativePath: {eq: "cafe.png"}) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED, quality: 80)
      }
    }
  }`);

  return (
    <Container>
      <AtEtno fontFamily="bebasNeue" fontSizes={[36, 48]} lineHeight={1.2} letterSpacing={[45, 9]} uppercase>
        <FormattedMessage id="homepage.mindfulness.at etno" />
      </AtEtno>
      <Mindfulness fontFamily="bebasNeue" fontSizes={[54, 72]} fontWeight={700} lineHeight={[1.09, 1.2]} letterSpacing={[68, 9]} uppercase underline>
        <TextWrapper lang={lang}><FormattedMessage id="homepage.mindfulness.mindfulness" /></TextWrapper>
        <Mark />
      </Mindfulness>
      <Text fontFamily="bebasNeue" fontSizes={[36, 48]} lineHeight={1.2} letterSpacing={[45, 9]} uppercase>
        <FormattedMessage id="homepage.mindfulness.train with us" />
      </Text>
      <Shadow />
      <Cafe image={data.cafe.childImageSharp.gatsbyImageData} alt="" />
    </Container>
  );
};

Mindfulnesss.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Mindfulnesss;
