import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import SwipeableViews from 'react-swipeable-views';
import { debounce } from '@utils/helpers';

import Brew from '@components/homepage/slider/brew';
import Roast from '@components/homepage/slider/roast';
import Serve from '@components/homepage/slider/serve';
import Images from '@components/homepage/slider/web/slider/images';

const SliderWrapper = styled.div`
  ${tw`relative md:py-30 lg:pt-0 lg:py-0`}

  ::before {
    top: 69px;
    transform: rotate(9deg);
    
    @media (min-width: 1921px) { transform: rotate(5deg); }
  }

  ::after {
    z-index: -1;
    bottom: -55px;
    transform: rotate(-4deg);
    
    @media (min-width: 1024px) {
      bottom: ${({ slide }) => (slide === 2 ? -79 : -49)}px;
    }
  }

  ::before, ::after {
    content: '';
    left: -300px;
    right: -300px;
    height: 540px;
    ${tw`absolute bg-pink`}
    
    @media (min-width: 1024px) { height: 468px }
    @media (min-width: 1441px) { height: 410px }
  }
`;

const Mask = styled.div`
  bottom: ${({ slide }) => (slide === 2 ? -54 : -24)}px;
  height: ${({ slide }) => (slide === 2 ? 500 : 450)}px;
  background: #f2c1b6;
  ${tw`hidden transition-all duration-300 lg:block lg:absolute lg:w-full`}

  @media (min-width: 1441px) { height: ${({ slide }) => (slide === 2 ? 595 : 455)}px; }
  @media (min-width: 1921px) { height: ${({ slide }) => (slide === 2 ? 595 : 460)}px; }
`;

const Views = tw(SwipeableViews)`lg:text-left`;

const Slider = ({ activeSlide, setActiveSlide }) => {
  const refContainer = useRef();

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      if (refContainer.current) refContainer.current.updateHeight();
    }, 200);

    window.addEventListener('resize', debouncedHandleResize);

    // eslint-disable-next-line no-unused-vars
    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return (
    <SliderWrapper slide={activeSlide}>
      <Mask slide={activeSlide} />
      <Images activeSlide={activeSlide} />
      <Views
        ref={refContainer}
        animateHeight
        index={activeSlide}
        onChangeIndex={setActiveSlide}
        springConfig={{
          duration: '0.4s',
          easeFunction: 'cubic-bezier(0.5, 1, 0.89, 1)',
          delay: '0s',
        }}
      >
        <Roast />
        <Brew />
        <Serve />
      </Views>
    </SliderWrapper>
  );
};

Slider.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default Slider;
