import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`pt-7 -px-12 pb-54 lg:pt-0 lg:px-0 lg:pb-60`;

const Header = () => (
  <>
    <Text fontFamily="bebasNeue" fontSizes={36} lineHeight={[1.08, 1.58]} letterSpacing={45}>
      <FormattedMessage id="homepage.just coffee.title" />
    </Text>
    <Title fontFamily="bebasNeue" fontSizes={[42, 56]} fontWeight={700} lineHeight={[1.05, 1.07]} letterSpacing={[52, 70]}>
      <FormattedMessage id="homepage.just coffee.title bold" />
    </Title>
  </>
);

export default Header;
