import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import * as Styles from '@components/homepage/slider/styles';
import ServePlaces from '@components/homepage/slider/serve-places';

const ColumnWrapper = styled.div`
  ${tw`lg:flex lg:justify-between lg:mb-115`}
  
  ${Styles.Content} {
    &:nth-of-type(1) { ${tw`lg:w-535`} }
    &:nth-of-type(2) { ${tw`lg:w-545 xl:-mr-10`} }
  }
`;

const CustomSpacing = tw(Styles.Spacing)`lg:max-w-none`;

const CustomTitle = tw(Styles.Title)`lg:whitespace-normal lg:w-440`;

const Serve = () => (
  <Styles.Wrapper>
    <Styles.Container>
      <CustomSpacing>
        <Styles.Hashtag fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
          <FormattedMessage id="homepage.3 ways.slider.serve.hash" />
        </Styles.Hashtag>
        <CustomTitle
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={700}
          lineHeight={[1.05, 1.02]}
          letterSpacing={[52, 70]}
        >
          <FormattedMessage id="homepage.3 ways.slider.serve.title" />
        </CustomTitle>
        <Styles.Description fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedHTMLMessage id="homepage.3 ways.slider.serve.desc" />
        </Styles.Description>
        <ColumnWrapper>
          <Styles.Content
            fontSizes={16}
            fontWeight={[400, 300]}
            lineHeight={[1.5, 1.4]}
            letterSpacing={20}
          >
            <FormattedHTMLMessage id="homepage.3 ways.slider.serve.left column" />
          </Styles.Content>
          <Styles.Content
            fontSizes={16}
            fontWeight={[400, 300]}
            lineHeight={[1.5, 1.4]}
            letterSpacing={20}
          >
            <FormattedHTMLMessage id="homepage.3 ways.slider.serve.right column" />
          </Styles.Content>
        </ColumnWrapper>
        <ServePlaces />
      </CustomSpacing>
    </Styles.Container>
  </Styles.Wrapper>
);

export default Serve;
