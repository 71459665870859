import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Header from '@components/homepage/header';
import Mindfulness from '@components/homepage/mindfulness';
import WeCare from '@components/homepage/we-care';
import JustCoffee from '@components/homepage/just-coffee';
import Slider from '@components/homepage/slider';
import Business from '@components/homepage/business';
import ForYou from '@components/homepage/for-you';
import Blog from '@components/homepage/blog';

const IndexPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.homepage' })}
        description={intl.formatMessage({ id: 'meta tags.description.homepage' })}
      />
      <Header />
      <Mindfulness lang={intl.locale} />
      <WeCare lang={intl.locale} />
      <JustCoffee />
      <Slider />
      <Business />
      <ForYou />
      <Blog />
    </Layout>
  );
};

export default IndexPage;
