import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import * as Styles from '@components/homepage/slider/styles';
import MarkDefault from '@images/mark';
import Text from '@components/text';
import { navigationWeServe } from '@utils/navigation';

const Header = tw.div`relative mt-30 -mb-10 lg:mb-15`;

const HeaderText = tw(Text)`relative`;

const Mark = styled(MarkDefault)`
  top: -16px;
  left: 40px;
  width: 222px;
  height: 80px;
  transform: rotate(4deg);
  ${tw`absolute`}

  .color {
    fill: #DBA79E;
  }
  
  @media (min-width: 640px) {
    left: 115px;
  }
  
  @media (min-width: 1024px) {
    top: -42px;
    left: 325px;
    width: 311px;
    height: 120px;
    transform: rotate(6deg);
  }

  @media (min-width: 1280px) {
    left: 376px;
  }
`;

const Subheader = styled(Text)`
  ${tw`mt-4 mx-10 lg:mt-0 lg:max-w-lg lg:mx-auto`}
  
  b { ${tw`lg:font-600`} }
`;

const Places = tw(Text)`mt-10 mx-30 lg:mt-15`;

const PlaceWrapper = tw.div`lg:text-center`;

const ServePlaces = () => (
  <PlaceWrapper>
    <Header>
      <Mark />
      <HeaderText
        fontFamily="bebasNeue"
        fontSizes={[36, 48]}
        lineHeight={[1.5, 1.02]}
        letterSpacing={[20, 60]}
      >
        <FormattedMessage id="homepage.3 ways.slider.serve.header" />
      </HeaderText>
    </Header>
    <Subheader
      fontSizes={[14, 16]}
      fontWeight={[400, 300]}
      lineHeight={1.5}
      letterSpacing={20}
    >
      <FormattedHTMLMessage id="homepage.3 ways.slider.serve.subheader" />
    </Subheader>
    <Places color="redDark" fontSizes={[14, 16]} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="homepage.3 ways.slider.serve.places" />
    </Places>
    <Styles.Link to={navigationWeServe.path} isExternal={navigationWeServe.externalLink}>
      <Styles.Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id={navigationWeServe.translation} />
        </Text>
      </Styles.Button>
    </Styles.Link>
  </PlaceWrapper>
);

export default ServePlaces;
