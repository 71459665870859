import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const Wrapper = styled.div`
  ${tw`relative flex justify-center items-center`}
  
  ${({ slide }) => (slide === 2 ? tw`lg:mt-24` : tw`lg:-mt-4`)}
`;

const DotWrapper = tw.div`flex justify-between items-center w-20 h-12 cursor-pointer`;

const Dot = styled.div`
  background-color: #6d0e00;
  ${tw`w-10 h-10 rounded-full opacity-50 transition-opacity duration-300`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Pagination = ({ items, activeSlide, setActiveSlide }) => (
  <Wrapper slide={activeSlide}>
    {items.map((item, index) => (
      <DotWrapper
        onClick={() => activeSlide !== index && setActiveSlide(index)}
        key={item}
      >
        <Dot active={activeSlide === index} />
      </DotWrapper>
    ))}
  </Wrapper>
);

Pagination.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default Pagination;
